import { Recipe } from '../interfaces/Recipe'
import { addDoc, collection, doc, DocumentData, getDoc, getDocs, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'
import { db } from '../config/firebase'

const recipeConverter = {
  toFirestore (recipe: Recipe): DocumentData {
    return { id: recipe.id, image: recipe.image, instructions: recipe.instructions, name: recipe.name }
  },
  fromFirestore (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Recipe {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = snapshot.data(options)!
    return { id: snapshot.id, image: data.image, name: data.name, instructions: data.instructions }
  }
}

export const getRecipes = async (): Promise<Recipe[]> => {
  return await new Promise<Recipe[]>((resolve, reject) => {
    getDocs(collection(db, 'recipes')
      .withConverter(recipeConverter)
    )
      .then(result => {
        const recipes: Recipe[] = []
        result.docs.forEach((doc) => {
          if (doc !== undefined) {
            recipes.push(doc.data())
          }
        })
        resolve(recipes)
      })
      .catch(error => reject(error))
  })
}

export const getRecipeById = async (id: string): Promise<Recipe> => {
  return await new Promise<Recipe>((resolve, reject) => {
    getDoc(doc(db, 'recipes', id)
      .withConverter(recipeConverter))
      .then(recipeDoc => {
        if (recipeDoc.exists()) {
          resolve(recipeDoc.data())
        } else {
          reject(new Error('Recipe not found'))
        }
      })
      .catch(error => reject(error))
  })
}

export const setupNewRecipe = async (name: string): Promise<string> => {
  return await new Promise<string>((resolve, reject) => {
    addDoc(collection(db, 'recipes'), { name })
      .then(result => resolve(result.id))
      .catch(error => reject(error))
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const recipes: Recipe[] = [
  {
    id: '1',
    name: 'Chilli bean thingy',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '2',
    name: 'Caramel cakes',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '3',
    name: 'Guiness cake',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '4',
    name: 'Enchiladas',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '5',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '6',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '7',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '8',
    name: 'test2',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '9',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '10',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '11',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '12',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '13',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '14',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '15',
    name: 'test2',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '16',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '17',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '18',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '19',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '20',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '21',
    name: 'test2',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '22',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '23',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '24',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '25',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  },
  {
    id: '26',
    name: 'test1',
    instructions: [],
    image:
      'https://images.unsplash.com/photo-1578985545062-69928b1d9587?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&w=1000&q=80'
  }
]
