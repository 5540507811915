import React, { FunctionComponent } from 'react'
import { Recipe } from '../interfaces/Recipe'
// import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import CardCover from '@mui/joy/CardCover'
import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'

interface RecipeCardProps {
  recipe: Recipe
}

const RecipeCard: FunctionComponent<RecipeCardProps> = ({ recipe }) => {
  return (
  // <Box
  //   component="ul"
  //   sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
  // >
      <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
        <CardCover>
          <img
            src="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
            srcSet="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x"
            loading="lazy"
            alt=""
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            fontWeight="lg"
            textColor="#fff"
            justifyItems="start"
            mt={{ xs: 12, sm: 18 }}
          >
            {recipe.name}
          </Typography>
        </CardContent>
      </Card>
  // </Box>
  )
}

export default RecipeCard
