import React, { useState } from 'react'
import Button from '@mui/joy/Button'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../config/firebase'

const AppMenu = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleSelect = (location: string): void => {
    setAnchorEl(null)
    navigate(location)
  }

  const handleSignOut = (): void => {
    signOut(auth).then(() => {
      navigate('/')
    })
  }

  return (
    <div onClick={handleClick}>
      <Button
        id="basic-demo-button"
        variant="soft"
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-labelledby="basic-demo-button"
      >
        <MenuItem onClick={() => handleSelect('/home')}>Home</MenuItem>
        <MenuItem onClick={() => handleSelect('/edit')}>Recipe</MenuItem>
        <MenuItem onClick={() => handleSelect('/edit')}>Catagory</MenuItem>
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default AppMenu
