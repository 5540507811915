import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRecipeById } from '../api/ApiRecipe'
import IPageProps from '../interfaces/page.interface'
import { Recipe } from '../interfaces/Recipe'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Textarea from '@mui/joy/Textarea'
import Button from '@mui/joy/Button'
import { Stack, Container, Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 } from 'uuid'

const EditRecipePage: React.FunctionComponent<IPageProps> = () => {
  const [recipe, setRecipe] = useState<Recipe>()
  const { recipeId } = useParams()
  const [error, setError] = useState<string>('')
  const [newStepInput, setNewStepInput] = useState<string>('')

  useEffect(() => {
    // If recipe id is undefined then we cant do anything else here
    if (recipeId === undefined) {
      setError('Recipe id is invalid')
      return
    }

    getRecipeById(recipeId).then(recipe => setRecipe(recipe))
  }, [recipeId])

  if (error !== '') return (<p>{error}</p>)

  if (recipe === undefined) return (<p>Cannot find recipe</p>)

  const uploadRecipeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    console.log('Upload image')
    const storage = getStorage()
    const uuid = v4()
    const storageRef = ref(storage, uuid)
    if (e.target.files !== null) {
      const file = e.target.files[0]

      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          console.log(url)
          const newRecipe = { ...recipe }
          newRecipe.image = url
          setRecipe(newRecipe)
        })
      })
    }
  }

  return (<>
      <h1>{recipe.name}</h1>
      <Container>
        <form>
          <FormControl>
            <FormLabel>Recipe name</FormLabel>
            <Input value={recipe.name} onChange={(event) => {
              const newRecipe = { ...recipe }
              newRecipe.name = event.target.value
              setRecipe(newRecipe)
            }} />
          </FormControl>
          <br />

          <Stack spacing={1}>
          {recipe.instructions
            ? recipe.instructions.map((instruction, index) => <div key={index}>
                <Textarea value={instruction} onChange={(event) => {
                  const newRecipe = { ...recipe }
                  newRecipe.instructions[index] = event.target.value
                  setRecipe(newRecipe)
                }}
                endDecorator={
                  <Box
                  sx={{
                    display: 'flex',
                    gap: 'var(--Textarea-paddingBlock)',
                    pt: 'var(--Textarea-paddingBlock)',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    flex: 'auto'
                  }}
                >
                  <Button sx={{ ml: 'auto' }}>Delete</Button>
                </Box>
                } />
              </div>)
            : <></>
            }
          </Stack>

          <Textarea
            required
            value={newStepInput}
            onChange={e => setNewStepInput(e.target.value)}
            placeholder={'New step...'}
            endDecorator={
              <Button onClick={() => {
                const newRecipe = { ...recipe }

                // We have to consider that this may be the first step
                if (newRecipe.instructions) {
                  newRecipe.instructions.push(newStepInput)
                } else {
                  newRecipe.instructions = [newStepInput]
                }

                setRecipe(newRecipe)
                setNewStepInput('')
              }} variant='outlined' fullWidth>
                <AddIcon />
              </Button>} />

              <FormControl>
                {recipe.image ? <img src={recipe.image} /> : <></>}
                <FormLabel>Recipe image</FormLabel>
                <Button
                  variant="solid"
                  component="label"
                >
                Upload File
                <input
                  onChange={uploadRecipeImage}
                  type="file"
                  accept="image/*"
                  hidden
                />
                </Button>
              </FormControl>

          <Button type="submit">Save recipe</Button>
        </form>
      </Container>

    </>)
}

export default EditRecipePage
