import React, { useState, useEffect } from 'react'
import { getRecipes } from '../api/ApiRecipe'

import '../App.css'
import IPageProps from '../interfaces/page.interface'
import Grid from '@mui/system/Unstable_Grid'
import { Recipe } from '../interfaces/Recipe'
import RecipeCard from '../components/RecipeCard'

const HomePage: React.FunctionComponent<IPageProps> = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([])

  useEffect(() => {
    getRecipes().then(result => setRecipes(result))
  }, [])

  return (<>
    <div className="home">

      <h1>Recipes</h1>
      {recipes.length !== 0
        ? (
        <Grid
          container
          spacing={2}
        >
          {recipes.map((recipe) => (
            <Grid xs={12} sm={4} key={recipe.id}>
              <RecipeCard recipe={recipe} />
            </Grid>
          ))}
        </Grid>
          )
        : (
        <p>No recipes found</p>
          )}
    </div>
    </>
  )
}

export default HomePage
