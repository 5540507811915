import React from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from '../../config/firebase'

function RequireAuth ({ children }: { children: JSX.Element }): JSX.Element {
  if (!auth.currentUser) {
    return <Navigate to="/" replace />
  }

  return children
}

export default RequireAuth
