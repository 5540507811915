const firebaseConfig = {
  apiKey: 'AIzaSyAtfxtt9WNSGbLwmwfNH7S2aAyjY00hk-U',
  authDomain: 'recipe-app-1f640.firebaseapp.com',
  projectId: 'recipe-app-1f640',
  storageBucket: 'recipe-app-1f640.appspot.com',
  messagingSenderId: '411949078274',
  appId: '1:411949078274:web:dd3a8b6ab5c665697c141b'
}

export default firebaseConfig
