import React, { useState, useEffect } from 'react'
import IPageProps from '../interfaces/page.interface'
import { Providers, auth } from '../config/firebase'
import { AuthProvider, signInWithRedirect } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/joy/CircularProgress'
import EmailAndPasswordSignIn from '../modules/auth/EmailAndPasswordSignIn'
import Button from '@mui/joy/Button'

const LandingPage: React.FunctionComponent<IPageProps> = props => {
  const [authenticating, setAuthenticating] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const navigate = useNavigate()

  const handleSignIn = (provider: AuthProvider): void => {
    if (error === '') setError('')
    setAuthenticating(true)
    signInWithRedirect(auth, provider)
  }

  useEffect(() => {
    // User is already logged in
    if (auth.currentUser) {
      navigate('/home')
    }

    setAuthenticating(false)

    // onAuthStateChanged(auth, user => {
    //   if (user) {
    //     getRedirectResult(auth)
    //       .then((result) => {
    //         if (result != null) {
    //           navigate('/home')
    //         }
    //         setAuthenticating(false)
    //       }).catch(error => {
    //         setAuthenticating(false)
    //         setError(error.message)
    //       })
    //   } else {
    //     setAuthenticating(false)
    //   }
    // })
  }, [auth.currentUser])

  return (
        <div>
            <h1>JLight Recipe book</h1>
            {authenticating
              ? <CircularProgress />
              : <>
              <EmailAndPasswordSignIn />
              <Button disabled={authenticating} onClick={() => handleSignIn(Providers.google)}>Sign in with google</Button>
            {error === '' ? <></> : <p>Error signing in: {error}</p>}</>}
        </div>
  )
}

export default LandingPage
