import React, { useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../config/firebase'
import Button from '@mui/joy/Button'
import TextField from '@mui/joy/TextField'
import Grid from '@mui/system/Unstable_Grid'
import { Box, Container } from '@mui/system'
import Alert from '@mui/joy/Alert'
import { useNavigate, Link } from 'react-router-dom'
import { COLORS } from '../../colours'

const EmailAndPasswordSignUp = (): React.ReactElement => {
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const navigate = useNavigate()

  const handleSignUp = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (password !== confirmPassword) {
      setError('Please recheck your password, your confirm password was not the same')
      return
    }

    setError('')
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        if (userCredential.user) {
          navigate('/home')
        }
        // ...
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  return <Container component="main" maxWidth="xs">
  <Box
    sx={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: COLORS.background,
      border: '2px hidden',
      borderRadius: '10px'
    }}
  >
    {error === '' ? <></> : <Alert variant='solid' color='warning'>{error}</Alert>}
    <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            autoComplete="new-password"
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => setConfirmPassword(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="solid"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <Grid container justifyContent="center">
        <Grid>
          <Link to="/">
            <Button>
              Already have an account? Sign in
            </Button>

          </Link>
        </Grid>
      </Grid>
    </Box>
  </Box>
</Container>
}

export default EmailAndPasswordSignUp
