import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../config/firebase'
import TextField from '@mui/joy/TextField'
import Button from '@mui/joy/Button'
import Grid from '@mui/system/Unstable_Grid'
import { Box, Container } from '@mui/system'
import Alert from '@mui/joy/Alert'
import { Link, useNavigate } from 'react-router-dom'
import { COLORS } from '../../colours'

const EmailAndPasswordSignIn = (): React.ReactElement => {
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const navigate = useNavigate()

  const handleSignIn = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (error !== '') setError('')
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        if (userCredential.user) {
          navigate('/home')
        }
        // ...
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  return <Container component="main" maxWidth="xs" >
  <Box
    sx={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: COLORS.background,
      border: '1px hidden',
      borderRadius: '3px'
    }}
  >
    {error === '' ? <></> : <Alert variant='solid' color='warning'>{error}</Alert>}
    <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
      <TextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        variant='solid'
        fullWidth
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid xs>
          <Link to="/forgot">
            <Button>
              Forgot password?
            </Button>
          </Link>
        </Grid>
        <Grid>
          <Link to="/signup">
            <Button>
              No account, Sign up!
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  </Box>
</Container>
}

export default EmailAndPasswordSignIn
