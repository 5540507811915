import React, { useEffect, useState } from 'react'
import { TextField, Button } from '@mui/joy'
import Grid from '@mui/system/Unstable_Grid'

import { getRecipes, setupNewRecipe } from '../api/ApiRecipe'
import IPageProps from '../interfaces/page.interface'
import { Stack } from '@mui/system'
import EditItemList, { EditableItem } from '../components/EditItemList'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/joy/Alert'
import { useNavigate } from 'react-router-dom'

const EditManagementPage: React.FunctionComponent<IPageProps> = () => {
  const [recipes, setRecipes] = useState<EditableItem[]>([])
  const [newRecipeInputControl, setNewRecipeInputControl] = useState<string>('')
  const [error, setError] = useState<string>('')
  const navigate = useNavigate()

  const handleNewRecipe = (name: string): void => {
    if (name === '') {
      setError('Invalid recipe name')
      return
    }
    setupNewRecipe(name).then(id => navigate(`/edit/recipe/${id}`))
  }

  const handleSelectEditRecipe = (id: string): void => {
    navigate(`/edit/recipe/${id}`)
  }

  useEffect(() => {
    getRecipes().then(result => setRecipes(result))
  }, [])

  return (<>
  <h1>Recipe management</h1>

      <Grid container spacing={2}>
        <Grid xs={12} sm={12}>
          <Stack spacing={1}>
          {error !== '' ? <Alert color='danger'>{error}</Alert> : <></>}
            <TextField fullWidth placeholder={'Search...'} />

            <TextField
              fullWidth
              value={newRecipeInputControl}
              onChange={e => setNewRecipeInputControl(e.target.value)}
              placeholder={'New recipe name...'}
               endDecorator={<Button onClick={() => handleNewRecipe(newRecipeInputControl)} variant='outlined' fullWidth><AddIcon /></Button>} />
            <TextField
              fullWidth
              placeholder={'New catagory...'}
               endDecorator={<Button variant='outlined' fullWidth><AddIcon /></Button>} />

          </Stack>
        </Grid>
        <Grid xs={12} sm={12}>
          <Tabs defaultValue={0} sx={{ borderRadius: 'lg' }}>
            <TabList>
              <Tab>Recipes</Tab>
              <Tab>Catagories</Tab>
            </TabList>
            <TabPanel value={0} sx={{ p: 2 }}>
              <EditItemList onSelect={handleSelectEditRecipe} items={recipes} />
            </TabPanel>
            <TabPanel value={1} sx={{ p: 2 }}>
              <EditItemList onSelect={handleSelectEditRecipe} items={recipes} />
            </TabPanel>
          </Tabs>
        </Grid>

      </Grid>
      </>
  )
}

export default EditManagementPage
