import React, { useState } from 'react'
import './App.css'

import HomePage from './pages/HomePage'
import { Route, Routes } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import RequireAuth from './modules/auth/AuthRoute'
import EmailAndPasswordSignUp from './modules/auth/EmailAndPasswordSignup'
import EditManagementPage from './pages/EditManagementPage'
import { auth } from './config/firebase'
import AppMenu from './components/AppMenu'
import EditRecipePage from './pages/EditRecipePage'

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  auth.onAuthStateChanged((user) => {
    setIsLoggedIn(user != null)
  })

  return (
    <div className="App">
      <Routes>
      <Route path='/signup' element={<EmailAndPasswordSignUp />} />
      <Route path='/home' element={<RequireAuth><HomePage name='Home page' /></RequireAuth>} />
      <Route path='/edit' element={<RequireAuth><EditManagementPage name='Edit recipe page' /></RequireAuth>} />
      <Route path='/edit/recipe/:recipeId' element={<RequireAuth><EditRecipePage name='Edit recipe page' /></RequireAuth>} />
      <Route path='/' element={<LandingPage name='Landing page' />} />
      </Routes>
      {isLoggedIn ? <div className='bottom-menu'><AppMenu /></div> : <></>}
    </div>
  )
}

export default App
