
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import firebaseConfig from '../firebaseConfig'
import { getFirestore } from 'firebase/firestore'
// Initialise the firebase application TODO sort out eslint with this intialization
/* eslint-disable */
const app = initializeApp(firebaseConfig)


// Add or Remove authentification methods here.
export const Providers = {
  google: new GoogleAuthProvider()
}

export const auth = getAuth(app);
export const db = getFirestore(app)
export default app;