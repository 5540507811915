import React, { FunctionComponent, Key } from 'react'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import ListItemButton from '@mui/joy/ListItemButton'
import EditIcon from '@mui/icons-material/Edit'

export interface EditableItem {
  id: string
  name: string
}

interface EditItemListProps {
  onSelect: Function
  items: EditableItem[]
}

const EditItemList: FunctionComponent<EditItemListProps> = ({ onSelect, items }) => {
  return (
        <List
        >
          {items.map(item =>
          <ListItem key={item.id as Key}>
            <ListItemButton onClick={() => onSelect(item.id)}>
              <ListItemDecorator>
                <EditIcon />
              </ListItemDecorator>
              {item.name}
            </ListItemButton>
          </ListItem>)}
        </List>
  )
}

export default EditItemList
